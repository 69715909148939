.blog {
  color: black;
}

/* test taken page */

.report-button {
  justify-content: center;
  align-items: center;
}

.report-button button {
  background-color: #31ae1e;
  border: none;
  color: white;
  padding: 5px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  font-size: 10px;
  border-radius: 5px;
}

.search-bar {
  width: 100%;
  max-width: 500px;
  background-color: #ecefff;
  display: flex;
  align-items: center;
  border-radius: 60px;
  padding: 1px 2px;
}

.search-bar input {
  background-color: transparent;
  flex: 1;
  border: none;
  outline: none;
  padding: 0px 20px;
  font-size: 15px;
  color: black;
}

.search-bar .icon {
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: black;
  min-width: 15px;
}

/* blog section */

.a_blog {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.blog-card {
  width: 250px;
  height: 250px;
  background-color: rgb(233, 232, 232);
  margin: 10px;
}

.blog-input input {
  min-width: 150px;
  width: 50%;
  outline: none;
  border: none;
  background-color: #ecefff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  margin-left: 10px;
}

.blog-input button {
  background-color: rgb(103, 128, 211);
  border: none;
  color: white;
  width: 50px;
  padding: 10px;
}

/* voucher section */

#voucheradd button {
  display: flex;
  padding: 10px;
  background-color: #31ae1e;
  border: none;
  color: white;
  font-size: medium;
  cursor: pointer;
}

#voucheradd button:hover {
  display: flex;
  padding: 10px;
  background-color: #108c20;
  border: none;
}

#voucher .action {
  padding: 10px;
  background-color: rgb(254, 157, 157);
}

#createVoucher {
  padding: 20px;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 62%;
  height: 75vh;
  margin: auto;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  box-shadow: 5px 5px 10px #c1c1c1;
  color: black;
}

#createVoucher h2 {
  text-align: center;
}

#createVoucher button {
  color: white;
  border: none;
  width: 100px;
  height: 30px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
}

.voucher-Btn {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

#createVoucher .close {
  background-color: rgb(247, 79, 79);
}

#createVoucher .generate {
  background-color: rgb(35, 143, 27);
}

.voucherForm {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

#createVoucher label {
  margin-bottom: 5px;
}

#createVoucher input[type="text"],
input[type="date"] {
  padding: 8px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

#createVoucher input[type="text"]:focus,
input[type="date"]:focus {
  outline: none;
  border-color: #007bff;
}

/* loader animation */
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  border: 8px solid #fff;
  border-bottom-color: #c6ccc3;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

/* filter */

#muti-filter {
  display: grid;
  grid-column: 2;
}

.pt {
  padding-top: 20px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  color: black;
  padding: 20px;
  border-radius: 5px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
}

.log-details {
  margin-bottom: 20px;
}

.modal-content button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #333;
}

.modal-content button:hover {
  background-color: #0056b3;
}

.log-details h3 {
  margin-top: 15px;
  margin-bottom: 10px;
}

.log-details p {
  margin: 5px 0;
}
