/* blog.css */

/* .a_main-container {
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
}

.newPost-modal {
  width: 80%;
  max-width: 600px;
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.BlogEditor label {
  display: block;
  margin-bottom: 5px;
  color: black;
}

.BlogEditor input[type="text"],
.BlogEditor input[type="date"],
.BlogEditor textarea,
.BlogEditor input[type="file"] {
  width: calc(100% - 16px); /* Adjusting width to account for padding */
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
input[type="file"]:not(:placeholder-shown) + label[for="img"] {
  color: black; /* Change to black when input has content */
}
.BlogEditor textarea {
  resize: vertical;
  min-height: 50px;
}

.BlogEditor button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.BlogEditor button:hover {
  background-color: #0056b3;
}

.createPostbtn {
  margin-left: 10px;
}
#newPostBtn{
  background-color: #007bff; /* Update background color */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 12px 24px; /* Increase padding */
  font-size: 16px; /* Increase font size */
  transition: background-color 0.3s;
}
/* New styles for the modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.newPost-modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-height: 80%; /* Limiting the height of the modal */
  overflow-y: auto; /* Enable vertical scrolling */
}