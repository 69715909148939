body {
  padding: 0%;
  border: 0%;
  box-sizing: border-box;
  height: 100px;
  background-color: white;
  /* overflow: hidden; */
}

.admin-main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  max-width: 700px;
  height: 400px;
  margin: 20vh auto;
  box-shadow: 5px 5px 10px #c1c1c1, -5px -5px 20px #f5f5f9;
  overflow: hidden;
  /* border-top-right-radius: 20px;
  border-bottom-right-radius: 20px; */
  background-color: white;
}

.admin-left {
  flex: 1.25;
}

.admin-left img {
  max-width: 100%;
  height: auto;
}

.admin-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.admin-right form {
  width: 80%;
}
.admin-right h2 {
  position: relative;
  margin-bottom: 35px;
}
.admin-right input {
  color: black;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #e3e1e1;
  border-radius: 5px;
  background-color: white;
  box-sizing: border-box;
  outline: none;
  
}

.admin-right button {
  width: 100%;
  padding: 10px;
  background-color: #71b1e0;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.admin-right button:hover {
  background-color: #508cad;
}

@media (max-width: 768px) {
  .admin-main {
    flex-direction: column;
    height: auto;
  }
  .admin-right input {
    margin-top: 5px;
    width: 100%;
  }
  .admin-left,
  .admin-right {
    width: 100%;
  }
  .admin-right button {
    margin-bottom: 20px;
  }
  .admin-right h2 {
    margin-top: 15px;
  }
}
